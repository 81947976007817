import store from 'datastore';
import i18n from 'datastore/i18n';
import bus from 'eventbus';
import { clamp } from 'lodash';
import PHPDate from 'PHPDate';
import router from 'router';
import { fyndwareClasses } from 'staticData';
import Tween from 'tween';
import inViewport from 'vue-mixins/in-viewport';
import waja from 'waja';

/*
@TODO lazy load google maps
let hasLoadedGoogleMaps = false;
let isLoadingGoogleMaps = false;
const googleMapsOnLoadCallbacks = [];
function loadGoogleMaps(callback) {
	if (hasLoadedGoogleMaps) {
		callback();
		return;
	}
	googleMapsOnLoadCallbacks.push(callback);
	if (isLoadingGoogleMaps) {
		return;
	}
	isLoadingGoogleMaps = true;
	require.ensure(['vue2-google-maps', 'vue2-google-maps/dist/components/cluster'], function () {
		const VueGoogleMaps = require('vue2-google-maps');
		console.log(VueGoogleMaps);
		const GmapCluster = require('vue2-google-maps/dist/components/cluster');

		Vue.use(VueGoogleMaps, {
			load: {
				key: 'AIzaSyAEDb7XQBKbCKGiojoXO2mKeUG4w2o_xec', // analytics@
				libraries: 'places',
			},
		});

		Vue.component('GmapCluster', GmapCluster);

		for (const cb of googleMapsOnLoadCallbacks) {
			cb();
		}

		hasLoadedGoogleMaps = true;
	});
}*/

let lastScrollObj = null;

function scrollToY (position, scrollDuration, animated = true) {
	scrollDuration = scrollDuration || 0.4;
	position |= 0;

	if (lastScrollObj) {
		Tween.destroy(lastScrollObj);
	}

	const xPos = window.scrollX || window.pageXOffset;
	if (! animated) {
		window.scrollTo(xPos, position);
		return;
	}
	const tweenObj = {
		get scrollY () {
			return window.scrollY || window.pageYOffset;
		},
		set scrollY (val) {
			window.scrollTo(xPos, val);
		},
	};
	lastScrollObj = tweenObj;
	new Tween(tweenObj, { scrollY: position }, scrollDuration, Tween.ease.inOutQuad);
}

function scrollTo (position, preventScrollIfInViewport, animated = true, verticalOffset = 0) {
	console.log('Scrolling to ', position);
	if (isNaN(position)) {
		const selector = position;
		position = document.querySelector(selector);
		if (! position) {
			console.error('Felaktig selector i utils.scrollTo (' + selector + ')');
			return;
		};
		const rect = position.getBoundingClientRect();
		if (preventScrollIfInViewport) {
			console.log('viewport check', rect);
			if (inViewport.methods.checkInViewport(rect, -10)) {
				console.log('scroll prevented');
				return;
			}
		}
		position = window.pageYOffset + rect.top + verticalOffset;
	}
	scrollToY(position, null, animated);
	return position;
}

const padCache = [
	'000000',
	'00000',
	'0000',
	'000',
	'00',
	'0',
	'',
];

function sendGoogleOptimizeEvent (eventName) {
	if (! window.dataLayer) {
		setTimeout(() => { sendGoogleOptimizeEvent(eventName); }, 100);
		return;
	}
	console.log('Sent Google Optimize event: ', eventName);
	window.dataLayer.push({ event: eventName }); // Trigga google optimize
}

// Cachenycklar för att slippa bygga en ny formatterinstans för varje pris på sidan (för formatPrice)
let cachedFormatterLocale = null;
let cachedFormatterCurrency = null;
let cachedFormatter = null;

function formatPrice (price, currency) {
	price = Math.round(price);
	if (! currency) {
		return price + '';
	}
	const locale = i18n.locale;

	if (cachedFormatterLocale === locale && cachedFormatterCurrency === currency) {
		return cachedFormatter.format(price);
	}
	const formatter = Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
		minimumFractionDigits: 0,
	});
	cachedFormatterLocale = locale;
	cachedFormatterCurrency = currency;
	cachedFormatter = formatter;

	return formatter.format(price);
};

/**
 * Builds a cart row key out of a cart row object.
 */
export function getCartRowKey (row) {
	return getCartRowKeyByProduct(row.product, row.unitPrice, row.extraData || undefined);
};

/**
 * Builds a cart row key from a product object.
 *
 * @param {*} product Product object to build key from.
 * @param {*} price Optional price parameter if the row has a custom price.
 * @param {*} extraData Optional extra data if the row contains extra data.
 */
export function getCartRowKeyByProduct (product, price = undefined, extraData = undefined) {
	if (price === undefined) {
		price = product.price.price;
	}
	let key = (product.id || product) + '@' + price * 1;
	if (product.price && product.price.pass) {
		key += '#' + product.price.pass;
	}
	if (extraData) {
		key += JSON.stringify(extraData);
	}

	return key;
};

export { sendGoogleOptimizeEvent };
export { scrollToY };
export { getAllVariants };
export { formatPrice };
export { daysBetweenDates };
export { urlFormat };
export { scrollTo };

export function getProductPath (product, pass, hidePass) {
	const route = standardRoute('product', product);
	if (! hidePass) {
		if (pass) {
			route.query = {
				pass,
			};
		} else if (product.price && product.price.pass) {
			route.query = {
				pass: product.price.pass,
			};
		}
	}
	return router.route(route);
};

export function inStockAnywhere (stock) {
	for (const store in stock) {
		if ((! isNaN(store) || store === 'download' || store === 'web') && stock[store] > 0) {
			return true;
		}
	}
	return false;
};

export function isProductReleased (release) {
	const date = new Date();
	date.setTime(release.timestamp * 1000);
	const now = new Date();
	return now >= date;
};

export function releaseToString (release) {
	return PHPDate(release.format, release.timestamp);
};

export function daysUntilRelease (release) {
	const date = new Date();
	date.setTime(release.timestamp * 1000);
	const now = new Date();
	return daysBetweenDates(date, now);
};

export function getProductRating (product) {
	if (product.averageRating) {
		const rating = product.averageRating;
		if (rating.rating) {
			return rating.rating;
		}
		return rating;
	}

	return null;
};

export function getParentProduct (product) {
	if (product.isFyndware) {
		return getAllVariants(product)[0];
	}
	return null;
};

export function isGroupProduct (product) {
	return (product.variants && product.variants.group);
};

export function getProductGroup (product) {
	if (product.variants) {
		return product.variants.group;
	}
	return null;
};

/**
 * Gets all variants of a product. Fyndwares are omitted by default.
 *
 * Returns at least one product (the product used as argument).
 */
function getAllVariants (product, omitFyndwares = true) {
	if (! product.variants) {
		return [product];
	}
	const variants = [];
	for (const variant of product.variants.list) {
		if (! (omitFyndwares && variant.isFyndware)) {
			variants.push(variant);
		}
	}
	return variants;
}

export function getAllVariantIds (product, omitFyndwares = true) {
	return getAllVariants(product, omitFyndwares).map(variant => variant.id);
};

/**
 * Gets the product variant ids to fetch reviews for.
 */
export function getReviewVariants (product) {
	const productIds = [];
	if (product.isFyndware) {
		// If the product is a fyndware then the variants are the parent
		// product and the fyndware itself.
		productIds.push(getParentProduct(product).id);
		productIds.push(product.id);
	} else {
		productIds.push(product.id);
	}
	return productIds;
};

export function displayStarRating (product) {
	if (product.averageRating) {
		const rating = product.averageRating;

		if (rating.ratingType && rating.ratingType === 'averageRating') {
			return true;
		} else if (rating.ratingType && rating.ratingType === 'hypefactor') {
			return false;
		}
	}

	return null;
};

export function getFyndwareCssClass (product) {
	if (! product.fyndwareClass) {
		return '';
	}
	switch (product.fyndwareClass.id) {
		case fyndwareClasses.one:
			return 'fynd-class-1';
		case fyndwareClasses.two:
			return 'fynd-class-2';
		case fyndwareClasses.three:
			return 'fynd-class-3';
		case fyndwareClasses.four:
			return 'fynd-class-4';
		case fyndwareClasses.five:
			return 'fynd-class-5';
		default:
			return 'fynd-class-default';
	}
	return '';
};

export function getStoreSuggestions (stock, suggestionOrder, favouriteStores = [], maxSuggestions = 4) {
	const favourites = [];
	const suggestions = [];
	for (const store of favouriteStores) {
		favourites.push(store);
		if (favourites.length >= maxSuggestions) {
			return {
				favourites,
				suggestions,
			};
		}
	}
	for (const store of suggestionOrder) {
		if (stock[store] && favourites.indexOf(~~store) < 0) {
			suggestions.push(~~store);
			if (favourites.length + suggestions.length >= maxSuggestions) {
				return {
					favourites,
					suggestions,
				};
			}
		}
	}

	for (const store in stock) {
		if (! isNaN(store) && stock[store] && favourites.indexOf(~~store) < 0 && suggestions.indexOf(~~store) < 0) {
			suggestions.push(~~store);
			if (favourites.length + suggestions.length >= maxSuggestions) {
				return {
					favourites,
					suggestions,
				};
			}
		}
	}
	if (suggestions.length === 0) {
		if (stock.supplier > 0) {
			suggestions.push('supplier');
		} else {
			suggestions.push(9999 /* övriga butiker */);
		}
	} else {
		for (const store of suggestionOrder) {
			if (favouriteStores.indexOf(~~store) < 0 && suggestions.indexOf(~~store) < 0) {
				suggestions.push(store);
				if (favourites.length + suggestions.length >= maxSuggestions) {
					break;
				}
			}
		}
	}
	return {
		favourites,
		suggestions,
	};
};

/**
 * Sorts an array of stores based on the geographical distance to a location.
 *
 * Warning, has side effects: the distance is assigned to each store object
 * as a property called dist.
 */
export function geoDistSort (location, stores) {
	if (! location || ! stores) return null;
	const { lat, lng } = location;
	const sortedStores = [];
	for (let i = 0; i < stores.length; i++) {
		const store = stores[i];
		const sLoc = store.location;
		if (! sLoc) continue;
		store.dist = latLngToDistance(lat, lng, sLoc.lat, sLoc.lng);
		sortedStores.push(store);
	}
	sortedStores.sort((a, b) => a.dist - b.dist);
	return sortedStores;
};

/**
 * Degrees to radians.
 */
export function deg2rad (deg) {
	return deg * (Math.PI / 180);
};

/**
 * Calculates the distance in kilometers between two coordiantes.
 */
export function latLngToDistance (lat1, lon1, lat2, lon2) {
	// Implemented by Dobby.
	const R = 6371; // Radius of the earth in km
	const dLat = deg2rad(lat2 - lat1);
	const dLon = deg2rad(lon2 - lon1);
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
		Math.sin(dLon / 2) * Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = R * c; // Distance in km
	return d;
};

/**
 * Formats a distance in kilometers to the most appropriate unit (km or m).
 */
export function formatDistance (distInKm) {
	const unit = distInKm < 1 ? 'm' : 'km';
	const num = distInKm < 1 ? distInKm * 1000 : distInKm;
	const options = {
		minimumFractionDigits: distInKm < 10 && distInKm >= 1 ? 1 : 0,
		maximumFractionDigits: distInKm < 10 && distInKm >= 1 ? 1 : 0,
	};
	return num.toLocaleString('sv-SE', options) + ' ' + unit;
};

export function splitPaymentFrom (price) { // @TODO region???
	// @TODO nu kollar vi bara klarna konto.
	const interest = 0.2; // 20% ränta
	const months = 24; // 24 månader
	const billingFee = 29; // aviavgift
	const startFee = 0; // uppläggningsavgift

	const periodRate = interest * 30 / 365;
	const paymentPerMonth = (Math.round(price * periodRate / (1.0 - Math.pow(1.0 + periodRate, -months)) + ((startFee + (billingFee * months)) / months)));

	return Math.max(paymentPerMonth, 50 /* lägsta månadsbelopp */);
};

export function focus (id, preventScroll) {
	const el = document.getElementById(id);
	if (! el) {
		console.error('Felaktigt id i utils.focus (' + id + ')');
		return;
	}
	console.log('focusing ' + id);
	if (preventScroll) {
		const x = window.scrollX;
		const y = window.scrollY;
		el.focus();
		window.scrollTo(x, y);
	} else {
		el.focus();
	}
};

export function formatProductPrice (priceObj, excludeVat = false) {
	let price = priceObj.price;
	if (excludeVat) {
		price -= priceObj.vat;
	}
	return formatPrice(price, priceObj.currency);
};


export function formatNumber (number) {
	return Intl.NumberFormat(i18n.locale).format(number);
};
export function error (code, message, data) {
	return vm => {
		console.error('PAGE ERROR!', code, message, data);
		bus.$emit('error', {
			code,
			message,
			data,
		});
	};
};

function daysBetweenDates (date1, date2) {
	const millisecondsPerDay = 24 * 60 * 60 * 1000;
	return Math.ceil((date1 - date2) / millisecondsPerDay);
}

function urlFormat (string) {
	if (! string || typeof string !== 'string') {
		return '';
	}
	return string
		.replace(/å/g, 'a')
		.replace(/Å/g, 'A')
		.replace(/ä/g, 'a')
		.replace(/Ä/g, 'A')
		.replace(/ö/g, 'o')
		.replace(/Ö/g, 'O')
		.replace(/[^a-zA-Z0-9\-\.\,\ ]/g, '')
		.trim()
		.replace(/[\s\-\,\.\-]+/g, '-');
};

export function lightenDarkenColor (col, amt) {
	let usePound = false;
	if (col[0] === '#') {
		col = col.slice(1);
		usePound = true;
	}

	const num = parseInt(col, 16);
	const r = clamp((num >> 16) * amt, 0, 255);
	const b = clamp(((num >> 8) & 0xFF) * amt, 0, 255);
	const g = clamp((num & 0xFF) * amt, 0, 255);

	let output = (g | (b << 8) | (r << 16)).toString(16);
	output = padCache[output.length] + output;
	return (usePound ? '#' : '') + output;
};
/**
 * Scrolls the page so that the specified element sits at the bottom of the
 * page.
 */
export function fixElementToBottomOfPage (element) {
	if (element) {
		const windowHeight = window.innerHeight;
		const rect = element.getBoundingClientRect();
		const position = window.pageYOffset + rect.top + rect.height - windowHeight;
		scrollToY(position);
	}
};

export function toPrecision (value, precision) {
	const result = Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
	return result;
};

export function confirm (body, title, accept, cancel) {
	title = title || i18n.t('CONFIRM.SURE');
	accept = accept || i18n.t('CONFIRM.ACCEPT');
	accept = accept || i18n.t('CONFIRM.CANCEL');
	return new Promise((resolve, reject) => {
		bus.$emit('confirmmodal', { resolve, reject, body, title, accept, cancel }); // @TODO finns ingen listener.. byt till modal.confirm, ligger i global-modal.vue
	});
};

export function seededRandom (seed) {
	return function random () {
		const x = Math.sin(seed++) * 10000;
		return x - Math.floor(x);
	};
};

export function filterIdenticalProducts (products) {
	const nameMap = {};
	return products.filter(p => {
		if (p.name in nameMap) return false;
		nameMap[p.name] = true;
		return true;
	});
};

export function routeFromHighlight (highlight) {
	const r = router.route;
	if (highlight.campaign) {
		return r(standardRoute('campaign', highlight.campaign));
	}
	if (highlight.product) {
		return r(getProductPath(highlight.product, null, null));
	}
	if (highlight.category) {
		return r(standardRoute('category', highlight.category));
	}
	if (highlight.manufacturer) {
		return r(standardRoute('manufacturer', highlight.manufacturer));
	}
	if (highlight.topListId) {
		return r(standardRoute('toplist', highlight.topListId));
	}
	console.error('routeFromHighlight does not know how to do this', highlight);
	// @TODO more options
};

export function convertToCdnUrl (url) {
	if (! url) {
		return null;
	}
	if (process.env.NODE_ENV !== 'production') {
		return url;
	}
	const urlsToConvert = [
		'/images/',
		'/files/',
		'/img/',
		'/api/dynimg/',
		'/api/avatar/',
	];
	for (const targetUrl of urlsToConvert) {
		if (url.indexOf(targetUrl) === 0) {
			// @TODO flytta till delad fil
			return '//cdn.webhallen.com' + url;
		}
	}
	return url;
};

export function convertToAchievementUrl (url, width, height) {
	if (! url) {
		return null;
	}

	if (process.env.NODE_ENV !== 'production') {
		return '/img/member/achievements/' + url + '.png';
	}

	return '//www.webhallen.com/cdn-cgi/image/height=' + height + ',width=' + width + ','
		+ 'quality=75/img/member/achievements/' + url + '.png';
}

export function categoryIconUrl (icon, overrideDarkTheme = false, color = null) {
	const isDark = store.getters['user/isDark'];
	if (overrideDarkTheme) {
		return convertToCdnUrl('/api/dynimg/category/' + icon + '/1A1A1D');
	}
	if (color) {
		return convertToCdnUrl('/api/dynimg/category/' + icon + '/' + color);
	}
	return convertToCdnUrl('/api/dynimg/category/' + icon + (isDark ? '/39485D' : '/1A1A1D'));
};

export function capitalize (str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export function hashCode (str) {
	// Based on Java hashCode from: http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
	let hash = 0;
	let chr = 0;
	if (str.length === 0) {
		return hash;
	}
	for (let i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

// Lägger onclick-handlers på relativa länkar så att de inte orsakar reload
export function fixRelativeLinks (rootElement) {
	const baseURL = window.location.protocol + '//' + window.location.hostname;
	const baseURLLength = baseURL.length;
	const links = rootElement.querySelectorAll('a');
	for (const link of links) {
		if (link.href.indexOf(baseURL) === 0) {
			const urlAfterBase = link.href.slice(baseURLLength);
			const region = urlAfterBase.slice(0, 7);

			if (['/se-sv/', '/dk-da/', '/fi-fi/'].indexOf(region) >= 0) {
				// Länk till gamla siten!
				link.href = link.href.replace('www.webhallen', 'classic.webhallen');
				continue;
			}

			// Om detta inte är en route i vår applikation så ska vi inte göra någon magi på länken
			if (router.resolve(urlAfterBase).route.matched.length === 0) {
				continue;
			}

			if (urlAfterBase.indexOf('/se/') !== 0) {
				link.href = baseURL + '/se' + urlAfterBase;
			}

			if (link.target === '_blank') {
				continue;
			}

			if (link.hash) { // @TODO och om resten av urlen är samma! inget ska ha ändrats förutom hash!
				link.onclick = () => {
					scrollTo(link.hash);
					return false;
				};
				continue;
			}

			link.onclick = (ev) => {
				if (! ev.ctrlKey) {
					router.push(urlAfterBase);
					return false;
				}
			};
		}
	}
};

export function getExtraProductData (products, vm) {
	const isAdmin = store.getters['user/isEmployee'];
	const isPanelShowing = store.getters['user/isAdminPanelShowing'];
	if (! isAdmin || ! isPanelShowing) {
		return;
	}
	if (! products.length) {
		return;
	}

	// Only request extra data on products that hasn't the 'hasReceivedExtraData' flag sat.
	products = products.filter(p => ! p.hasReceivedExtraData);
	if (products.length <= 0) {
		return;
	}

	const data = { 'products': products.map(p => p.id) };
	waja.get('product/extra-data')
		.data(data)
		.on('success', res => {
			if (! res.data) {
				return;
			}
			for (const product of products) {
				// Set a flag on this product so we won't be requesting extra data for it again.
				vm.$set(product, 'hasReceivedExtraData', true);

				if (! res.data.hasOwnProperty(product.id)) {
					continue;
				}
				for (const key in res.data[product.id]) {
					const value = res.data[product.id][key];
					if (product.hasOwnProperty(key)) {
						// We don't want to override any properties already sat on this product.
						continue;
					}
					vm.$set(product, key, value);
				}
			}
		}).go();
};

export function htmlToText (html) { // OBS! Använd inte denna om syftet är säkerhet. Detta är till för att konvertera html till t.ex. metabeskrivningar
	if (! html) {
		return '';
	}
	return html.replace(/<style>.*?<\/style>/g, '').replace(/<[^>]+>/ig, '').replace(/\s+/g, ' ').trim();
};

export function dayNrToString (weekDayNr) {
	switch (weekDayNr) {
		case 1:
			return 'Monday';
		case 2:
			return 'Tuesday';
		case 3:
			return 'Wednesday';
		case 4:
			return 'Thursday';
		case 5:
			return 'Friday';
		case 6:
			return 'Saturday';
		case 7:
			return 'Sunday';
		default:
			return '';
	}
};

// Ceil to nearest value by power of (scale)
export function ceilToNearest (value, scale = null) {
	if(! scale) {
		const exponent = Math.floor(Math.log10(value));
		scale = Math.pow(10,exponent);
	}
	return Math.ceil(value / scale) * scale;
};

export function floorToNearest (value, scale) {
	return Math.floor(value / scale) * scale;
};

export function isNumber (value) {
	return ! isNaN(value) && isFinite(value);
};

export function addProductToGaEcommerce (ga, product, extraData) {
	const category = product.categoryTree;

	const data = {
		id: product.id,
		name: product.name || 'N/A',
		category,
		brand: product.manufacturer ? product.manufacturer.name : 'N/A',
		price: product.price ? product.price.price : 0,
	};

	if (extraData) {
		for (const key in extraData) {
			data[key] = extraData[key];
		}
	}

	ga.ecommerce.addProduct(data);
	console.log('Added e-commerce product data', data);
};

export function setCaretPosition (el, position) {
	let node;

	let n;
	const nodes = [];
	const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false);
	while (n = walk.nextNode()) {
		nodes.push(n);
	}

	for(let i = 0; i < nodes.length; i++) {
		if (position > nodes[i].nodeValue.length && nodes[i + 1]) {
			position -= nodes[i].nodeValue.length;
		} else {
			node = nodes[i];
			break;
		}
	}

	if (! node) {
		return;
	}

	const selection = window.getSelection();
	const range = document.createRange();
	range.setStart(node, position);
	range.collapse(true);
	selection.removeAllRanges();
	selection.addRange(range);
};

export function getNavStatus () {
	return store.state.navStatus;
};

// Finds current path for category tree in hamburger nav etc
export function findCurrentPath () {
	const navStatus = getNavStatus();
	let children = [];
	const path = [];
	if (navStatus.section) {
		const sections = getSections();
		for (let i = sections.length - 1; i >= 0; --i) {
			if (sections[i].id === navStatus.section.id) {
				children = sections[i].categories;
				path.push(i);
				break;
			}
		}
	}

	for (const category of navStatus.categories) {
		for (let i = children.length - 1; i >= 0; --i) {
			if (children[i].id === category.id) {
				children = category.subCategories;
				if (! children.length) {
					break;
				}
				path.push(i);
				break;
			}
		}
	}
	return path;
};

export function currentRoute () {
	const route = router.history.current;
	return route;
}

export function getSections () {
	const navStatus = store.state.navStatus;
	const route = currentRoute();

	let tree = [];
	if ((route.name === 'manufacturer' || route.name === 'manufacturer-page') && navStatus.menu.manufacturerTree) {
		tree = navStatus.menu.manufacturerTree;
	} else if (navStatus.menu.staticTree) {
		tree = navStatus.menu.staticTree;
	}
	return tree;
}

// Finds current position in category tree
export function current (path, showStatic) {
	const navStatus = getNavStatus();
	const currentPath = path;

	let current = {
		title: 'Huvudkategorier', // @TODO översätt
		id: null,
		icon: null,
		route: null,
		active: true,
	};
	const previous = [];
	let search = path.length > 0 && ! showStatic ? getSections() : navStatus.menu.staticTree;
	const onManufacturerPage = search !== navStatus.menu.staticTree;

	for (let i = 0; i < currentPath.length; i++) {
		const item = search[currentPath[i]];
		previous.push(current);

		let name = i === 0 ? 'section' : 'category';
		name = (onManufacturerPage) ? 'manufacturer' : name;

		if (name === 'category') {
			item = {
				sectionName: navStatus.section?.name || navStatus.menu.staticTree[currentPath[0]].name,
				...item,
			};
		}
		let route = standardRoute(name, item);

		if (onManufacturerPage && i !== 0) {
			route = {
				params: {
					id: navStatus.menu.manufacturerTree[0].id,
					name: urlFormat(navStatus.menu.manufacturerTree[0].name),
					categoryId: item.id,
					categoryName: urlFormat(item.name),
				},
				name: 'manufacturer-page',
			};
		}

		current = {
			title: item.name,
			id: item.id,
			icon: item.icon,
			active: i === navStatus.categories.length,
			route: route,
			hasNext: !! item.subCategories || !! item.categories,
		};
		search = item.subCategories || item.categories;
	}
	current.previous = previous;
	if (currentPath.length > 0) {
		current.color = (showStatic ? navStatus.menu.staticTree : getSections())[currentPath[0]].color;
	}
	if (navStatus.menu.status !== 1) {
		return current;
	}

	current.next = search.map(item => {
		const next = item.subCategories || item.categories;
		let name = previous.length === 0 ? 'section' : 'category';

		if (name === 'category') {
			item = {
				sectionName: navStatus.section?.name || navStatus.menu.staticTree[currentPath[0]].name,
				...item,
			};
		}
		let route = standardRoute(name, item);

		//Manufacturer pages
		if (onManufacturerPage) {
			route = {
				params: {
					id: navStatus.menu.manufacturerTree[0].id,
					name: urlFormat(navStatus.menu.manufacturerTree[0].name),
					categoryId: item.id,
					categoryName: urlFormat(item.name),
				},
				name: 'manufacturer-page',
			};
		}
		return {
			title: item.name,
			id: item.id,
			icon: item.icon,
			route: route,
			hasNext: next.length > 0,
		};
	});
	return current;
};

/* Returns head path for current navigation */
export function headPath (item, onManufacturerPage = null, routeParams) {
	let name = item.currentPath.length > 1 ? 'category' : 'section';
	if (onManufacturerPage) {
		if (item.currentPath.length > 1) {
			return manufacturerPagePath(item.currentPath[0], routeParams);
		}
		name = 'manufacturer';
	}

	return router.route(standardRoute(name, { ...item.currentPath[0], ...item }));
};

/* Returns back path based on current navigation */
export function backPath (item, onManufacturerPage = null, routeParams) {
	if (item.currentPath.length > 1) {
		if (onManufacturerPage) {
			if (item.currentPath.length > 2) {
				return manufacturerPagePath(item.currentPath[1], routeParams);
			}

			return router.route(standardRoute('manufacturer', item.currentPath[1]));
		}
		let name = item.currentPath.length > 2 ? 'category' : 'section';

		return router.route(standardRoute(name, { ...item.currentPath[1], ...item }));
	}
	return router.route({
		name: 'index',
	});
};

export function manufacturerPagePath (section, routeParams) {
	const item = {
		section,
		routeParams
	}

	return router.route({
		name: 'manufacturer-page',
		params: {
			id: routeParams.id,
			name: urlFormat(routeParams.name),
			categoryId: section.id,
			categoryName: urlFormat(section.name),
			title: formatHeadTagTitle('manufacturer-page', item),
		},
	});
};

export function createCategoryPath (category) {
	if (category?.path?.length) {
		if (category.path[category.path.length - 1].id !== category.id) {
			return {
				path: [...category.path, category],
				...category,
			}
		} else {
			return category;
		}
	} else {
		return {
			path: [category],
			...category,
		}
	}
}

export function memberRoute (member, page, label = null) {
	let formattedLabel;

	if (label) {
		formattedLabel = i18n.t('MEMBER.' + label);
	} else {
		const labelFromPage = page.split(/[.]/).pop().toUpperCase().replace(/-/g, '_');
		formattedLabel = i18n.t('MEMBER.' + labelFromPage);
	}

	const item = {
		id: member.id,
		memberPage: page,
		name: member.username,
		label: formattedLabel,
	};

	return standardRoute('member', item);
};

export function standardRoute(routeName, item = null) {
	let formattedItem = item;
	let finalRouteName = routeName;

	if (item.id === 3144 || item.id === 6813) {
		formattedItem.id = 6813;
		finalRouteName = 'campaign';
	} else if (routeName === 'category') {
		formattedItem = createCategoryPath(item);
	}

	return {
		name: finalRouteName === 'member' ? item.memberPage : finalRouteName,
		params: {
			id: formattedItem?.id ?? null,
			name: urlFormat(formattedItem.name),
			title: formattedItem?.title ?? formatHeadTagTitle(finalRouteName, formattedItem),
		},
	};
};

export function formatHeadTagTitle (routeName, item) {
	let title = '';

	switch (routeName) {
		case 'section':
		case 'campaign':
		case 'toplist':
		case 'manufacturer':
			title = item.metaTitle ?? item.name;
			break;
		case 'info':
		case 'store':
			title = item.name;
			break;
		case 'article':
			title = item.metaTitle ?? item.heading;
			break;
		case 'category':
			if (item.metaTitle) {
				title = item.metaTitle;
			} else {
				let subTitle = '';
				if (item.path.length < 2) {
					subTitle = item.sectionName;
				} else {
					subTitle = item.path[item.path.length - 2].name;
				}
				title = (item.name + ' - ' + subTitle);
			}
			break;
		case 'campaigns':
			if (item?.id) {
				title = item.name + ' - Missa inte våra nuvarande kampanjpriser';

			} else {
				title = 'Alla kampanjer - Hitta produkter till otroliga kampanjpriser';
			}
			break;
		case 'manufacturer-page':
			title = item.routeParams.name + ' ' + item.section.name;
			break;
		case 'member':
			title = item.name + ' - ' + item.label;
			break;
		case 'product':
			if (item.metaTitle) {
				title = item.metaTitle;
			} else {
				const sectionName = item.section ? item.section.name : '';
				const subTitle = item.mainCategoryPath && item.mainCategoryPath.length ? item.mainCategoryPath[0].name : sectionName;
				title = item.name + (subTitle.length ? ' - ' + subTitle : '');
			}
			break;
		default:
			break;
	}

	return title;
};

/* 	Detects changes in document height, call in mounted as follows:
*	this.utils.onElementHeightChange(document.body, function(){
*	});
*/
export function onElementHeightChange (elm, callback) {
	let lastHeight = elm.clientHeight, newHeight;
	(function run(){
		newHeight = elm.clientHeight;
		if ( lastHeight != newHeight )
			callback();
		lastHeight = newHeight;

		if ( elm.onElementHeightChangeTimer )
			clearTimeout(elm.onElementHeightChangeTimer);

		elm.onElementHeightChangeTimer = setTimeout(run, 200);
	})();
};


/* 	Gets height of entire document, preferrably run with function above as follows:
* 	const vm = this;
*	vm.utils.onElementHeightChange(document.body, function(){
*   	vm.utils.getDocumentHeight();
*	});
*/
export function getDocumentHeight () {
	const body = document.body,
		html = document.documentElement;
	const height = Math.max( body.scrollHeight, body.offsetHeight,
		html.clientHeight, html.scrollHeight, html.offsetHeight );
	return height;
};

export function shouldShowStock(product, user) {
	if (isProductInSecretMode(product)) {
		if (canUserViewProductInSecretMode(user, product)) {
			return true;
		}
		return false;
	} else {
		if (product.statusCodes && product.statusCodes.indexOf(11/* Mail when available*/) >= 0) {
			return false;
		} else {
			return true;
		}
	}
}

export function isProductInSecretMode(product) {
	return product.minimumRankLevel > 0 && product.statusCodes && product.statusCodes.indexOf(11/* Mail when available*/) >= 0;
}

export function canUserViewProductInSecretMode(user, product) {
	return product.minimumRankLevel > 0 && user && user.rankLevel >= product.minimumRankLevel && product.statusCodes.indexOf(11/* Mail when available*/) >= 0;
}

export function priceExclVat (priceObj) {
	return priceObj.price - priceObj.vat;
}

export function getPriceObject (product, priceOverride = null, priceOverrideVat = null, subscriptionProduct = null) {
	/* set currentPrice */
	let currentPrice = product.price;

	if (priceOverride || subscriptionProduct) {
		currentPrice = {
			price: priceOverride,
			vat: priceOverride * priceOverrideVat,
			currency: product.price ? product.price.currency : 'SEK', // @TODO finns det någon annan källa till valuta?
		};
	}

	/* set comparisonPrice */
	let comparisonPrice = null;
	if (product.regularPrice
		&& parseFloat(product?.regularPrice?.price) > parseFloat(currentPrice?.price)
		&& validCampaignLength(currentPrice)) {
		if (subscriptionProduct) {
			comparisonPrice = currentPrice.price;
		} else if (product.lowestPrice
			&& parseFloat(product.lowestPrice?.price) < parseFloat(currentPrice.price)
			&& ! product.isFyndware) {
			comparisonPrice = product.lowestPrice;
		} else if (product.lowestPrice
			&& parseFloat(product.lowestPrice?.price) >= parseFloat(currentPrice.price)
			&& parseFloat(product.lowestPrice?.price) < parseFloat(product.regularPrice.price)
			&& ! product.isFyndware) {
			comparisonPrice = product.lowestPrice;
		} else {
			comparisonPrice = product.regularPrice;
		}
	}

	if (product.price && priceOverride && parseFloat(priceOverride) < parseFloat(product.price.price)) {
		comparisonPrice = this.product.price;
	}

	/* set levelOnePrice */
	let levelOnePrice = null;
	if (product?.levelOnePrice && product.price.type !== 'campaign') {
		levelOnePrice = product.levelOnePrice;
	}

	return {
		currentPrice,
		comparisonPrice,
		levelOnePrice,
	};
}

export function getPriceSavings (priceObject, excludeVat) {
	const currentPrice = priceObject.currentPrice;
	const comparisonPrice = priceObject.comparisonPrice;
	let savings = 0;
	let percentage = 0;

	if (comparisonPrice) {
		/* set savings kr */
		if (excludeVat) {
			savings = priceExclVat(comparisonPrice) - priceExclVat(currentPrice);
		} else {
			savings = comparisonPrice?.price - currentPrice.price;
		}

		/* set percentage */
		if (excludeVat) {
			percentage = Math.round((savings / priceExclVat(comparisonPrice)) * 100);
		} else {
			percentage = Math.round((savings / comparisonPrice.price) * 100);
		}
	}

	return savings >= 99 || percentage >= 10 ? { savings, percentage } : null;
}

export function validCampaignLength (price) {
	let validCampaignLength = new Date(price.startAt);
	validCampaignLength.setDate(validCampaignLength.getDate() + 28);
	const today = new Date();

	return today < validCampaignLength;
}