<template>
	<li :class="{'hover': hover}">
		<h3>
			<router-link
				:to="getPath(category)"
				@click.native="click($event, getPath(category))"
				@touchend.native="doubleTouch($event, getPath(category))"
			>
				{{category.name}}
			</router-link>
		</h3>
		<ul class="list-unstyled sub-category">
			<li
				v-for="(subCategory, index) in visibleSubCategories"
				:key="'sub-category-' + subCategory.id + '-' + index"
				:class="{'dropdown': hasChildren, 'hover': hover}"
			>
				<router-link
					:to="getPath(subCategory)"
					@click="click($event, getPath(subCategory))"
					@touchend="doubleTouch($event, getPath(subCategory))"
				>
					{{subCategory.name}}
				</router-link>
			</li>
			<li
				v-if="subCategories.length <= 0"
				:class="{'dropdown': hasChildren, 'hover': hover}"
			>
				<router-link
					:to="getPath(category)"
					@click="click($event, getPath(category))"
					@touchend="doubleTouch($event, getPath(category))"
				>
					{{category.name === "Nya produkter" ? "Visa alla nyheter" : category.name}}
				</router-link>
			</li>
			<li v-if="subCategories.length > subCategoryLimit-1 && subCategories.length !== subCategoryLimit">
				<router-link
					class="underlined-link"
					:to="getPath(category.icon === 'lego' ? legoDestination : category)"
					@click="click($event, getPath(category.icon === 'lego' ? legoDestination : category))"
					@touchend="doubleTouch($event, getPath(category.icon === 'lego' ? legoDestination : category))"
				>
					Visa fler
				</router-link>
			</li>
		</ul>
	</li>
</template>

<script>
import { standardRoute } from 'utils';
import bus from 'eventbus';
import router from 'router';
import { trackEvent } from 'gtm';

export default {
	name: 'site-nav-category',
	props: {
		category: {
			type: Object,
			required: true,
		},
		darken: {
			type: Boolean,
			default: true,
		},
		blackArrow: {
			type: Boolean,
			default: false,
		},
		subCategoryLimit: {
			type: Number,
			default: 5,
		},
	},
	data () {
		return {
			hover: false,
			legoDestination: {
				icon: 'lego',
				id: 5269,
				name: 'Allt LEGO',
				region: 'se',
				title: 'LEGO - Här listar vi allt LEGO vi har'
			}
		};
	},
	computed: {
		path () {
			let pathName = this.category.isCampaign ? 'campaign' : 'category';
			let item = this.category;
			if (this.category.name === 'Kampanjer') {
				pathName = 'campaigns';
			}

			if (pathName !== 'campaigns') {
				item.name = this.category.name;
			}

			return router.route(standardRoute(pathName, item));
		},
		hasChildren () {
			return this.subCategories.length > 0;
		},
		subCategories () {
			return this.category.subCategories;
		},
		visibleSubCategories () {
			return this.subCategories
			.slice(0, this.subCategoryLimit - (this.subCategories.length !== this.subCategoryLimit))
			.map(subCat => {
				return {
					sectionName: this.category.sectionName,
					...(! subCat.isCampaign && { path : [...this.category.path, subCat] }),
					...subCat,
				}
			});
		},
	},
	beforeDestroy () {
		window.removeEventListener('touchend', this.removeTouch);
		bus.$off('close-top-nav', this.close);
	},
	mounted () {
		bus.$on('close-top-nav', this.close);
		this.calculateHeight();
	},
	methods: {
		getPath (category) {
			if (! category.id) { return; }

			let pathName = category.isCampaign ? 'campaign' : 'category';
			let item = category;
			if (category.name === 'Kampanjer') {
				pathName = 'campaigns';
			}

			if (pathName !== 'campaigns') {
				item.name = category.name;
			}

			let path = router.route(standardRoute(pathName, item));
			return path;
		},
		removeTouch (event) {
			if (! this.$el.contains(event.target) && this.$el !== event.target) {
				this.hover = false;
				window.removeEventListener('touchend', this.removeTouch);
			}
		},
		close () {
			this.hover = false;
		},
		click (event, path = null) {
			trackEvent('gaEvent', 'Interactions', 'Menu Click', 'Top Menu');
			if (! event.ctrlKey) {
				event.preventDefault();
				this.$router.push(path || this.path);
			}
		},
		doubleTouch (event, path = null) {
			if (! this.hasChildren) {
				this.$router.push(path || this.path);
				return;
			}

			if (! this.hover) {
				this.hover = true;
				window.addEventListener('touchend', this.removeTouch);
			} else {
				window.removeEventListener('touchend', this.removeTouch);
				this.$router.push(path || this.path);
			}
		},
		calculateHeight () {
			const num = Math.max(this.visibleSubCategories.length, 1) + (this.subCategories.length > this.visibleSubCategories.length);
			this.$el.style.gridRowEnd = 'span ' + (66+num*22);
		},
	},
	watch: {
		category: {
			handler(val) {
				this.calculateHeight();
			},
			deep: true,
		},
	},
};
</script>
