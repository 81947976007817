import waja from 'waja';
import alert from 'alert';
import store from './index';

function defaultState () {
	return {
		storeGroupFormat: [
			{
				name: 'LOCATIONS.STOCKHOLM', // @TODO gillar inte att ha namn här..
				stores: ['City', 2, 15, 32, 'Galleria/Handelsplats', 9, 19, 14, 11, 'Motorväg', 31, 16],
			},
			{
				name: 'LOCATIONS.GOTHEMBURG',
				stores: [27],
			},
			{
				name: 'LOCATIONS.MORE_CITIES',
				stores: [5],
			},
		],
		myStores: [],
		darkTheme: false,
	};
}
store.registerModule('userSettings', {
	namespaced: true,
	state: defaultState(),

	actions: {
		addStore ({ commit }, storeId) {
			commit('addStore', storeId);
			waja.post('starred-store')
				.data({
					storeId,
				}).on('507', res => {
					alert('Du kan max ha tre stjärnmarkerade butiker!'); // @TODO översätt
					commit('removeStore', storeId);
				}).go();
		},
		removeStore ({ commit }, storeId) {
			commit('removeStore', storeId);
			waja.delete('starred-store/' + storeId).go();
		},
	},

	mutations: {
		starredStores (state, stores) {
			state.myStores = stores;
		},
		addStore (state, store) {
			state.myStores.push(store);
		},
		removeStore (state, store) {
			const index = state.myStores.indexOf(store);
			if (index >= 0)	state.myStores.splice(index, 1);
		},
	},
});
