import root from 'components/root/root';
import store from 'datastore';
import i18n from 'datastore/i18n';
import { clearCurrentNav } from 'datastore/navStatus';
import bus from 'eventbus';
import { isEqual } from 'lodash';
import metaHandler from 'metaHandler';
import redirects from 'routerRedirects';
import { prerender } from 'staticData';
import tagHandler from 'tagHandler';
import { sendGoogleOptimizeEvent, urlFormat } from 'utils';
import Vue from 'vue';
import VueRouter from 'vue-router';
import waja from 'waja';
import { generalDataLayer, pageview, testPageview } from 'gtm';

/*
** Lazy Loading
*/
const pageSwish = () => import('components/pages/checkout/swish/index.vue');
const pageCampaign = () => import('components/pages/campaign');
const pageCategory = () => import('components/pages/category');
const pageTopList = () => import('components/pages/toplist');
const pageHome = () => import('components/pages/home');
const pageInfo = () => import('components/pages/info');
const pageSection = () => import('components/pages/section');
const pageError = () => import('components/pages/error');
Vue.component('page-error', pageError);
const appleCto = () => import('components/configurator/apple-cto');
const pageOrder = () => import('components/pages/order');
const pageStore = () => import('components/pages/store');
const pageManufacturer = () => import('components/pages/manufacturer');
const pageManufacturerPage = () => import('components/pages/manufacturer-page');
const pageArticle = () => import('components/pages/single-article');
const pageMailSent = () => import('components/pages/mail-sent');
const pageCampaignList = () => import('components/pages/campaign-list');
const pageResetPassword = () => import('components/pages/reset-password');
const pageSearch = () => import('components/pages/search');

/* bundle: ProductPage
** Components needed while on single product
*/
const pageProduct = () => import(/* webpackChunkName: "ProductPage" */ 'components/pages/product');

/* bundle: Cart
** Components needed while toggling cartbutton and processing order in cart
*/
const checkout = () => import(/* webpackChunkName: "Cart" */ 'components/pages/checkout/index.vue');
const pageCart = () => import(/* webpackChunkName: "Cart" */ 'components/pages/cart');
const pageCheckoutComplete = () => import(/* webpackChunkName: "Cart" */ 'components/pages/checkout-complete');

/* bundle: Member
** Components for member page
*/
const pageMember = () => import(/* webpackChunkName: "Member" */ 'components/pages/member');
const pageMemberGame = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/game');
const pageMemberAvatar = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/avatar');
const pageMemberOrders = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/orders');
const pageMemberOffers = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/offers');
const pageMemberPortal = () => import(/* webpackChunkName: "Member" */ 'components/pages/member-portal');
const pageMemberFriends = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/friends');
const pageMemberProfile = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/profile');
const pageMemberToplist = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/toplist');
const pageMemberBenefits = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/benefits');
const pageMemberPayments = () => import(/* webpackChunkName: "Member" */ 'components/pages/wh-payments/wh-payments');
const pageMemberWishlists = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/wishlists');
const pageMemberSupplyDrop = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/supply-drop/index.vue');
const pageMemberKnighthood = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/knighthood');
const pageMemberAchievements = () => import(/* webpackChunkName: "Member" */ 'components/pages/member/achievements');


const routes = [
	{
		path: '/:region([a-z][a-z])?',
		component: root,
		children: [
			{
				path: '/',
				name: 'index',
				component: pageHome,
			},
			{
				path: 'member/:id(\\d+)?',
				alias: [
					'member/:id(\\d+)',
					'm/:id(\\d+)',
					'm/:id(\\d+)-:name([A-Za-z\\d-]*)?',
				],
				component: pageMember,
				children: [
					{
						name: 'member',
						path: '/',
						redirect: { name: 'member.orders' },
					},
					{
						name: 'member.achievements',
						path: 'achievements/:aid(\\d+)?',
						component: pageMemberAchievements,
					},
					{
						name: 'member.offers',
						path: 'offers',
						component: pageMemberOffers,
					},
					{
						name: 'member.benefits',
						path: 'benefits',
						component: pageMemberBenefits,
					},
					{
						name: 'member.orders',
						path: 'orders/:oid(\\d+)?',
						component: pageMemberOrders,
					},
					{
						name: 'member.friends',
						path: 'friends',
						component: pageMemberFriends,
					},
					{
						name: 'member.toplist',
						path: 'toplist',
						component: pageMemberToplist,
					},
					{
						name: 'member.wh-payments',
						path: 'payments',
						component: pageMemberPayments,
					},
					{
						name: 'member.profile',
						path: 'profile',
						component: pageMemberProfile,
					},
					{
						name: 'member.avatar',
						path: 'avatar',
						component: pageMemberAvatar,
					},
					{
						name: 'member.supply-drop',
						path: 'supply-drop',
						component: pageMemberSupplyDrop,
					},
					{
						name: 'member.knighthood',
						path: 'knighthood',
						component: pageMemberKnighthood,
					},
					{
						name: 'member.wishlists',
						path: 'wishlists/:wid(\\d+)?',
						component: pageMemberWishlists,
					},
					{
						name: 'member.game',
						path: 'game',
						component: pageMemberGame,
					},
				],
			},
			{
				path: 'product/:id(\\d+)-:name([A-Za-z\\d-]*)?',
				alias: [
					'product/:id(\\d+)',
					'p/:id(\\d+)',
					'p/:id(\\d+)-:name([A-Za-z\\d-]*)?',
					':id(\\d+)',
					':id(\\d+)-:name([A-Za-z\\d-]*)?',
				],
				name: 'product',
				component: pageProduct,
			},
			{
				path: 'category/:id(s?\\d+)-:name([A-Za-z\\d-]*)?',
				alias: [
					'category/:id(s?\\d+)',
					'c/:id(s?\\d+)',
					'c/:id(s?\\d+)-:name([A-Za-z\\d-]*)?',
				],
				name: 'category',
				component: pageCategory,
			},
			{
				path: 'campaigns/:id(\\d+)?',
				name: 'campaigns',
				component: pageCampaignList,
			},
			{
				path: 'campaign/:id(s?\\d+)-:name([A-Za-z\\d-]*)?',
				alias: [
					'campaign/:id(s?\\d+)',
				],
				name: 'campaign',
				component: pageCampaign,
			},
			{
				path: 'manufacturer/:id(\\d+)-:name([A-Za-z\\d-]*)?',
				alias: [
					'manufacturer/:id(\\d+)',
				],
				name: 'manufacturer',
				component: pageManufacturer,
			},
			{
				path: 'manufacturer/:id(\\d+)-:name([A-Za-z\\d-]*)/:categoryId(s?\\d+)-:categoryName([A-Za-z\\d-]*)?',
				alias: [
					'manufacturer/:id(\\d+)/:categoryId(s?\\d+)',
				],
				name: 'manufacturer-page',
				component: pageManufacturerPage,
			},
			{
				path: 'toplist/:id(\\d+)-:name([A-Za-z\\d-]*)?',
				alias: [
					'toplist/:id(\\d+)',
				],
				name: 'toplist',
				component: pageTopList,
			},
			{
				path: 'section/:id(\\d+)-:name([A-Za-z\\d-]*)?',
				name: 'section',
				alias: [
					'section/:id(\\d+)',
					's/:id(\\d+)',
					's/:id(\\d+)-:name([A-Za-z\\d-]*)?',
				],
				component: pageSection,
			},
			{
				path: 'search/:searchString(.*)?',
				name: 'search',
				component: pageSearch,
			},
			{
				path: 'checkout/swish/:orderId(\\d+)',
				name: 'swishPayment',
				component: pageSwish,
			},
			{
				path: 'checkout/:paymentLink(.*)?',
				name: 'checkout',
				component: checkout,
			},
			{
				path: 'order/:id',
				name: 'order',
				component: pageOrder,
			},
			{
				path: 'checkout-complete/:id(\\d+)?',
				name: 'checkout-complete',
				component: pageCheckoutComplete,
			},
			{
				path: 'info/:id(\\d+)?-:name([A-Za-z\\d-]*)?',
				alias: [
					'info/:id(\\d+)?',
					'i/:id(\\d+)?',
					'i/:id(\\d+)?-:name([A-Za-z\\d-]*)?',
				],
				name: 'info',
				component: pageInfo,
			},
			{
				path: 'store/:id(\\d+)-:name([A-Za-z\\d-]*)?',
				alias: [
					'store/:id(\\d+)',
				],
				name: 'store',
				component: pageStore,
			},
			{
				path: 'mail-sent',
				name: 'mail-sent',
				component: pageMailSent,
			},
			{
				path: 'cart/:products',
				name: 'cart',
				component: pageCart,
			},
			{
				path: 'reset-password',
				name: 'reset-password',
				component: pageResetPassword,
			},
			/*
			* Portal
			*/
			{
				path: 'offers',
				name: 'member-offers',
				component: pageMemberPortal,
			},
			{
				path: 'orders',
				name: 'member-orders',
				component: pageMemberPortal,
			},
			{
				path: 'benefits',
				name: 'member-benefits',
				component: pageMemberPortal,
			},
			{
				path: 'wishlists',
				name: 'member-wishlists',
				component: pageMemberPortal,
			},
			{
				path: 'wh-payments',
				name: 'member-payments',
				component: pageMemberPortal,
			},
			{
				path: 'profile',
				name: 'member-profile',
				component: pageMemberPortal,
			},
			{
				path: 'achievements',
				name: 'member-achievements',
				component: pageMemberPortal,
			},
			{
				path: 'avatar',
				name: 'member-avatar',
				component: pageMemberPortal,
			},
			{
				path: 'supplydrop',
				name: 'member-supply-drop',
				component: pageMemberPortal,
			},
			{
				path: 'knighthood',
				name: 'member-knighthood',
				component: pageMemberPortal,
			},
			{
				path: 'toplist',
				name: 'member-toplist',
				component: pageMemberPortal,
			},
			{
				path: 'game',
				name: 'member-game',
				component: pageMemberPortal,
			},
			{
				path: 'apple-cto/:id(\\d+)?',
				name: 'apple-cto',
				component: appleCto,
			},
			{
				path: 'article/:id(s?\\d+)-:name([A-Za-z\\d-]*)?',
				alias: [
					'article/:id(s?\\d+)',
				],
				name: 'article',
				component: pageArticle,
			},
			{
				path: 'delbetalning',
				name: 'delbetalning',
				component: pageMemberPayments,
			},

			/*
			* 404
			*/
			{
				name: '404',
				path: '/error/404',
				component: pageError,
			},
		],
	},
];

function setTitle (title, includeSiteName = true) {
	const siteName = 'Webhallen';
	if (! (title && title.length)) {
		title = siteName;
	} else {
		title = includeSiteName ? (title + ' | ' + siteName) : title;
	}
	const titleTag = document.getElementById('pageTitle');
	titleTag.innerText = title;
}

const router = new VueRouter({
	preload: true, // @TODO bevaka status på https://github.com/vuejs/vue-router/pull/1504
	routes,
	mode: 'history',
	scrollBehavior (to, frm, savedPosition) {
		if (frm && to.name === frm.name && isEqual(to.params, frm.params)) {
			// Vi vill inte göra något här om det enda som uppdateras är hash eller query, så vi hoppar ur :)
			return;
		}

		let handled = false;
		for (let i = to.matched.length - 1; i >= 0; i--) {
			const component = to.matched[i].components.default;

			if (component.noRouteScroll) {
				return;
			}
			if (to.name == 'campaign' && frm.name == 'campaign') {
				return { x: 0, y: 0 };
			}
			if (component.willSendScrollTriggerEvent) {
				handled = true;
				break;
			}
		}
		if (handled) {
			return new Promise(resolve => {
				bus.$off('saved-scroll.apply');
				bus.$on('saved-scroll.apply', () => {
					if (savedPosition) {
						console.log('SAVED-SCROLL.APPLY!', savedPosition.y);
					}

					bus.$off('saved-scroll.apply');
					Vue.nextTick(() => {
						console.log('Applying scroll', 'document height: ' + document.body.scrollHeight);
						if (savedPosition) {
							resolve(savedPosition);
						} else {
							resolve({ x: 0, y: 0 });
						}
					});
				});
			});
		}

		if (savedPosition) {
			return savedPosition;
		} else if (savedPosition === null) {
			return { x: 0, y: 0 };
		}
	},
});
// window.history.scrollRestoration = 'manual';

function objectMatching (obj, pattern) {
	for (const key in pattern) {
		const val = pattern[key];
		if (val instanceof Object) {
			if (! objectMatching(obj[key], val)) {
				return false;
			}
			continue;
		}
		if (val != obj[key]) { // eslint-disable-line eqeqeq
			return false;
		}
	}
	return true;
}

let hasSentOptimizeActivateEvent = false;
let hasPushedGeneralDataLayer = false;

router.beforeEach(function (to, frm, next) {
	const cookies = {
		marketing: store.getters['cookieConsent/marketing'],
		necessary: store.getters['cookieConsent/necessary'],
		personalisation: store.getters['cookieConsent/personalisation'],
	};
	const isLoggedIn = store.getters['user/isLoggedIn'];
	const title = to.name === 'index' || ! (to.params.title || to.meta.title) ? 'För gamers och teknikentusiaster' : (to.params.title || to.meta.title);

	/**
	 * There are different functions in utils.js to help format and set
	 * the site title. The initial load for the site is handled in php, but every
	 * link click is handled in frontend, so the title has to be sent with
	 * the params (to.params.title).
	 * Check similar links to see what function to use.
	 **/
	if (hasPushedGeneralDataLayer && to.name !== 'index' && ! (to.params.title || to.meta.title)) {
		console.error('OBS! Title param missing for link! ;-;');
	}

	/* A generalDataLayer push triggers from head, so this ensures first push isn't double */
	if (! hasPushedGeneralDataLayer) {
		hasPushedGeneralDataLayer = true;
		pageview(document.title, router.getBaseUrl() + to.path, isLoggedIn, cookies);
	} else {
		generalDataLayer(isLoggedIn, cookies);
		/* Only update title and pageview if it differs from previous title */
		if (title !== frm.params?.title) {
			setTitle(title);
			pageview(document.title, router.getBaseUrl() + to.path, isLoggedIn, cookies);
		}
	}

	if (! hasSentOptimizeActivateEvent) {
		hasSentOptimizeActivateEvent = true; // Behöver bara skicka detta en gång
		sendGoogleOptimizeEvent('optimize.activate');
	}

	// Perform SEO cleanup when switching page.
	if (frm && ! (to.name === frm.name && isEqual(to.params, frm.params))) {
		// Removes all added meta tags and other tags that might have been added.
		tagHandler.deleteAll();
	}

	if (to.path.indexOf('/admin') === 0) {
		next();
	} else if (! to.matched.length) {
		next('error/404/?p=' + to.path);
	} else {
		for (const redirect of redirects) {
			if (objectMatching(to, redirect.from)) {
				if (typeof redirect.to === 'string') {
					window.location.href = redirect.to;
					return;
				}
				if (prerender) {
					store.dispatch('prerender/redirect', redirect.to);
					return;
				}
				next(redirect.to);
				return;
			}
		}
		next();
	}
});

router.firstPageLoaded = false;

router.afterEach(function (to, frm) {
	if (! router.firstPageLoaded) {
		Vue.nextTick(() => {
			// Vue.$ga.time({
			//  timingCategory: 'performance',
			//  timingVar: 'initial-load',
			//  timingLabel: 'initial-load-label',
			//  timingValue: (Date.now() - window.performance.timing.connectStart)
			// });
			// console.log('PAGE LOAD: ' + (Date.now() - window.performance.timing.connectStart) + 'ms');
		});
		const loadingScreen = window.loadingScreen;
		if (loadingScreen.parentNode) {
			loadingScreen.parentNode.removeChild(loadingScreen);
		}
		router.firstPageLoaded = true;
	}

	if (frm && to.name === frm.name && isEqual(to.params, frm.params)) {
		// Vi vill inte göra något här om det enda som uppdateras är hash eller query, så vi hoppar ur :)
		return;
	}
	//Set a default canonical link that points to the page itself.
	tagHandler.set({
		id: 'canonical',
		type: 'link',
		attributes: {
			rel: 'canonical',
			href: router.getBaseUrl() + to.path,
		},
	});

	// Set a default alternate link that points to itself with lang info
	tagHandler.set({
		id: 'alternate',
		type: 'link',
		attributes: {
			rel: 'alternate',
			hreflang: 'sv-SE',
			href: router.getBaseUrl() + to.path,
		},
	});

	let navigationHandled = false;
	let componentHandlesPrerender = null;
	let componentHandlesMeta = false;
	for (let i = to.matched.length - 1; i >= 0; i--) {
		const component = to.matched[i].components.default;
		if (component.handleNavigation) {
			if (! navigationHandled) {
				navigationHandled = component.handleNavigation();
			}
		}

		if (component.handlesMeta) {
			componentHandlesMeta = true;
		}

		if (component.handlesPrerender) {
			componentHandlesPrerender = true;
		}
	}
	if (! navigationHandled) {
		clearCurrentNav();
	}
	if (! componentHandlesMeta) {
		// Set default title and description.
		metaHandler.setDefault();
	}
	bus.$emit('page.load', to);
	Vue.nextTick(() => {
		if (document.activeElement && document.activeElement.blur) {
			document.activeElement.blur();
		}

		function pageLoaded () {
			/* Test ffor moving page view */
			const cookies = {
				marketing: store.getters['cookieConsent/marketing'],
				necessary: store.getters['cookieConsent/necessary'],
				personalisation: store.getters['cookieConsent/personalisation'],
			};
			const isLoggedIn = store.getters['user/isLoggedIn'];
			const title = to.name === 'index' || !(to.params.title || to.meta.title) ? 'För gamers och teknikentusiaster' : (to.params.title || to.meta.title);

			/**
			 * There are different functions in utils.js to help format and set
			 * the site title. The initial load for the site is handled in php, but every
			 * link click is handled in frontend, so the title has to be sent with
			 * the params (to.params.title).
			 * Check similar links to see what function to use.
			 **/
			if (hasPushedGeneralDataLayer && to.name !== 'index' && !(to.params.title || to.meta.title)) {
				console.error('OBS! Title param missing for link! ;-;');
			}

			if (title !== frm.params?.title) {
				setTitle(title);
				testPageview(document.title, router.getBaseUrl() + to.path, isLoggedIn, cookies);
			}

			// Once all requests have been completed.
			waja.onceClean(() => {
				// And the components have been updated.
				Vue.nextTick(() => {
					// Announce that the navigation is complete.
					bus.$emit('router:done');

					if (! componentHandlesPrerender) {
						// If the page does not handle the prerendering notification
						// manually, then tell the prerendering server that the
						// page has been loaded.
						store.dispatch('prerender/pageLoaded');
					}
				});
			});
		};

		// Check if the site is being prerendered.
		if (prerender) {
			// If so, then set a short timeout so that don't miss any waja
			// requests from being fired in the components.
			setTimeout(pageLoaded, 15);
		} else {
			pageLoaded();
		}
	});
});

router.route = function (r) {
	if (typeof r === 'string') {
		r = this.resolve(r).route;
	}
	const route = Object.assign({}, r);
	route.params = Object.assign({
		region: route.name !== 'index' ? i18n.regionCode : null,
	}, route.params);
	if (route.params.name) {
		route.params.name = urlFormat(route.params.name);
	}
	return route;
};

router.getBaseUrl = () => {
	if (prerender && process.env.NODE_ENV === 'production') {
		return 'https://www.webhallen.com';
	}
	return window.location.protocol + '//' + window.location.hostname;
};

router.routeToUrl = route => {
	return router.getBaseUrl() + router.resolve(route).href;
};

router.setTitle = setTitle;

export default router;