require('error-logging');

import 'url-search-params-polyfill';
import 'whatwg-fetch';
import localSessionStorage from 'localSessionStorage';

// Is kundterminal?
const isCustomerTerminal =  window.location.href.includes('?kundterminal');
localSessionStorage.set('isCustomerTerminal', isCustomerTerminal);

function intlSanityCheck () {
	const num = Intl.NumberFormat(
		'sv-SE',
		{
			style: 'currency',
			currency: 'SEK', minimumFractionDigits: 0,
		}
	).format(1);

	return num === '1 kr';
}

import VueRouter from 'vue-router';
import router from 'router';

import store from 'datastore';

import i18n from 'datastore/i18n';
import 'datastore/cart';
import 'datastore/productFilters';
import 'datastore/user';
import 'datastore/userSettings';
import 'datastore/shippingPrices';
import 'datastore/syncedStorage';
import 'datastore/navStatus';
import 'datastore/productTips';
import 'datastore/viewHistory';
import 'datastore/comments';
import 'datastore/modal';
import 'datastore/prerender';
import 'datastore/cookieConsent';
import 'datastore/shippingMethodRelatedInfo';

import { sync } from 'vuex-router-sync-previous';
import Vue from 'vue';
import bus from 'eventbus';
import components from 'componentProvider';
import VueTouch from 'vue-touch';

// import VueAnalytics, { onAnalyticsReady } from 'vue-analytics';
// import VueGtm from 'vue-gtm';

import PortalVue from 'portal-vue';
import { urlFormat } from 'utils';
import prerenderAPI from 'prerenderAPI';
import VueCountdown from '@chenfengyuan/vue-countdown';
import { convertToCdnUrl } from 'utils';

import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';

const isIE11 = !! window.MSInputMethodContext && !! document.documentMode;
if (! isIE11) { // Detta har sönder en massa grejer i IE11
	Vue.use(VueGoogleMaps, {
		load: {
			key: 'AIzaSyAEDb7XQBKbCKGiojoXO2mKeUG4w2o_xec', // analytics@
			libraries: 'places',
		},
	});

	GmapCluster.props.styles.default = function() {
		return [
			{
				textColor: 'white',
				url: convertToCdnUrl('/img/markercluster/m1-2.png'),
				height: 52,
				width: 53,
			},
			{
				textColor: 'black',
				url: convertToCdnUrl('/img/markercluster/m2.png'),
				height: 55,
				width: 56,
			},
			{
				textColor: 'black',
				url: convertToCdnUrl('/img/markercluster/m3.png'),
				height: 65,
				width: 66,
			},
			{
				textColor: 'black',
				url: convertToCdnUrl('/img/markercluster/m4.png'),
				height: 77,
				width: 78,
			},
			{
				textColor: 'black',
				url: convertToCdnUrl('/img/markercluster/m5.png'),
				height: 89,
				width: 90,
			},
		];
	};

	Vue.component('GmapCluster', GmapCluster);
}

import 'connectionMonitor';
import 'abTest';
import 'apiVersionHeaderHandler';
import { prerender } from 'staticData';

window.addEventListener('keydown', e => {
	if (e.keyCode === 27) {
		bus.$emit('modal.close');
	}
});

sync(store, router);
VueTouch.config.swipe = {
	direction: 'horizontal',
};

// Vue.use(VueAnalytics, {
// 	id: window.gaPropertyId, // Sätts i app.blade.php
// 	router,
// 	batch: {
// 		enabled: true, // enable/disable
// 		amount: 2, // amount of events fired
// 		delay: 250, // delay in milliseconds
// 	},
// 	debug: {
// 		sendHitTask: process.env.NODE_ENV === 'production' && ! prerender,
// 	},
// 	ecommerce: {
// 		enabled: true,
// 		enhanced: true,
// 	},
// 	autoTracking: {
// 		pageviewTemplate (route) { // Bestämmer hur vi spårar sidvisningar
// 			const url = new URL(window.location.href);
// 			const searchParams = new URLSearchParams(url.search);

// 			const censoredSearchParams = ['email', 'username', 'token', 'kco'];

// 			for (const name of censoredSearchParams) {
// 				if (searchParams.has(name)) {
// 					searchParams.set(name, 'CENSORED');
// 				}
// 			}

// 			url.search = searchParams.toString();

// 			let urlString = url.toString();
// 			let path = route.path;

// 			const censoredParams = {
// 				'checkout-complete': ['id'],
// 				'order': ['id'],
// 				'swishPayment': ['orderId'],
// 			}[route.name]; // Plocka ut den vi är på direkt

// 			if (censoredParams) {
// 				for (const name of censoredParams) {
// 					if (route.params[name]) {
// 						// Censurera parametern både i location och page-värdena
// 						urlString = urlString.replace(route.params[name], 'CENSORED');
// 						path = path.replace(route.params[name], 'CENSORED');
// 					}
// 				}
// 			}

// 			if (searchParams.toString()) {
// 				path += '?' + searchParams.toString();
// 			}

// 			// Det verkar som om att vi inte får skicka med location-värdet pga SPA
// 			// (standardbeteende är att det *inte* ändras när man navigerar i span)
// 			// om vi behöver censurera det fältet med så lär vi behöva trolla lite så att det inte ändras.
// 			// Håll koll så att sessioner med landing page = (not set) inte ökar.
// 			return path;
// 		},
// 	},
// });

// Vue.use(VueGtm, {
// 	id: window.gtmPropertyId, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
// 	enabled: process.env.NODE_ENV === 'production', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
// 	debug: process.env.NODE_ENV !== 'production', // Whether or not display console logs debugs (optional)
// 	loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
// 	vueRouter: router,
// });

// onAnalyticsReady().then(() => {
// 	Vue.$ga.require('GTM-PH8CQ9S'); // AB-test (google optimize)
// });

Vue.use(VueRouter);

VueTouch.registerCustomEvent('tripletap', {
	type: 'tap',
	taps: 3,
});

Vue.use(VueTouch);

Vue.use(PortalVue);

Vue.component(VueCountdown.name, VueCountdown);

for (const key in components) {
	Vue.component(key, components[key]);
}

function forceRerender (component) {
	component.$children.forEach(forceRerender);
	component.$forceUpdate();
}
/* if (navigator.userAgent.indexOf('HeadlessChrome') === -1) {
	window.something.justcrash.hej();
} */

store.commit('i18n/region', router.resolve(window.location.pathname).route.params.region);

const app = new Vue({
	router,
	template: '<div id="app" :class="appClass"><background /><router-view/><global-modal /></div>',
	mounted () {
		bus.$on('vue.rerender', () => {
			forceRerender(this);
		});
	},
	computed: {
		appClass () {
			if (! this.$route) {
				return null;
			}
			return ('route-' + this.$route.name).replace(/\./g,'-');
		},
	}
});

function mountApplication() {
	setTimeout(() => {
		app.$mount('#app');
	}, 0);
}

router.updateParams = function (params) {
	if (! params) {
		return;
	}

	params.region = i18n.regionCode;
	params.name = urlFormat(params.name);

	const route = {
		name: app.$route.name,
		params: Object.assign({}, app.$route.params, params),
		query: app.$route.query,
	};
	router.replace(route);
};

router.setQuery = function (query) {
	const route = {
		name: app.$route.name,
		params: app.$route.params,
		query,
	};
	router.replace(route);
};

router.updateQuery = function (query) {
	this.setQuery(Object.assign({}, app.$route.query, query));
};

bus.$on('localization:locale-updated', () => {
	router.updateParams({});
});

window.app = app;
window.Vue = Vue;
// The prerendering server accesses the API through the window object.
window.prerenderAPI = prerenderAPI;

require('livechat');
require('@bambora/checkout-sdk-web');

import metaHandler from 'metaHandler';
metaHandler.setDefault();

import 'doge';

// Polyfill för Object.entries
if (!Object.entries) {
	Object.entries = function (obj) {
		const ownProps = Object.keys(obj);
		let i = ownProps.length,
		resArray = new Array(i); // preallocate the Array
		while (i--)
			resArray[i] = [ownProps[i], obj[ownProps[i]]];

		return resArray;
	};
}

if (! window.Intl || ! intlSanityCheck()) {
	// @TODO detta är en polyfill, i framtiden kommer den inte behövas https://caniuse.com/#feat=internationalization
	console.log('Using intl polyfill');
	require.ensure([], function() {
		require('intl');
		require('intl/locale-data/jsonp/sv-SE');
		// require('intl/locale-data/jsonp/da-DK');
		// require('intl/locale-data/jsonp/fi-FI');
		// require('intl/locale-data/jsonp/en-US');
		window.Intl = window.IntlPolyfill;
		mountApplication();
	}, 'polyfills');
} else {
	if (prerender) { // Vi vill inte köra prerenderern utan dessa chunks
		require.ensure([], () => {
			require.ensure([], () => {
				mountApplication();
			}, 'stage-2');
		}, 'deep-routes');
	} else {
		mountApplication();
	}
}

export default app;
